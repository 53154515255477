import { createRouter, createWebHistory } from 'vue-router';
// import defaultLayout from 'layouts/SideNavOuterToolbar';
import defaultLayout from '@/letapps-vue/layouts/sidenav/SideNav.vue';
import simpleLayout from '@/letapps-vue/layouts/SimpleLayout.vue';

import HomePage from '@/pages/HomePage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import TestPage from '@/pages/TestPage.vue';
import Error404Page from '@/letapps-vue/pages/Error404Page.vue';
import Log from '@/pages/log/LogPage.vue';
import BrugerePage from '@/pages/adgang/BrugerePage.vue';
import ScrollTest from '@/pages/ScrollTest.vue';
import VisualGuidelines from '@/pages/VisualGuidelines.vue';
import ResponsiveBoxDemo from '@/pages/ResponsiveBoxDemo.vue';
import Roller from '@/pages/adgang/RollerPage.vue';
import { RettighedsType } from '@/types/rettighed';
import SletRevisionPage from '@/pages/support/SletRevisionPage.vue';

// function loadView(view) {
//   return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
// }

export const ROUTE_BRUGERADM = '/brugeradm';
export const ROUTE_BRUGERE = '/brugeradm/brugere';
export const ROUTE_ROLLER = '/brugeradm/roller';
export const ROUTE_LOG = '/log';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_SUPPORT = '/support';
export const ROUTE_SLETREVISION = '/support/sletrevision';

// eslint-disable-next-line new-cap
const router = createRouter({
  routes: [
    {
      path: '/',
      meta: {
        rettighed: RettighedsType.AppAdmin,
        layout: defaultLayout,
      },
      component: HomePage,
    },
    {
      path: '/test',
      meta: {
        rettighed: RettighedsType.AppAdmin,
        layout: defaultLayout,
      },
      component: TestPage,
    },
    {
      path: ROUTE_BRUGERADM,
      meta: {
        rettighed: RettighedsType.AdminBruger,
        layout: defaultLayout,
      },
      redirect: ROUTE_BRUGERE,
    },
    {
      path: `${ROUTE_BRUGERE}/:id?`,
      meta: {
        rettighed: RettighedsType.AdminBruger,
        layout: defaultLayout,
      },
      component: BrugerePage,
    },
    {
      path: ROUTE_ROLLER,
      meta: {
        rettighed: RettighedsType.AdminRolle,
        layout: defaultLayout,
      },
      component: Roller,
    },
    {
      path: ROUTE_SUPPORT,
      meta: {
        rettighed: RettighedsType.AdminSupportSletRevision,
        layout: defaultLayout,
      },
      redirect: ROUTE_SLETREVISION,
    },
    {
      path: ROUTE_SLETREVISION,
      meta: {
        rettighed: RettighedsType.AdminSupportSletRevision,
        layout: defaultLayout,
      },
      component: SletRevisionPage,
    },
    {
      path: ROUTE_LOG,
      meta: {
        rettighed: RettighedsType.AdminLog,
        layout: defaultLayout,
      },
      component: Log,
    },
    {
      path: `${ROUTE_LOG}/:id`,
      meta: {
        rettighed: RettighedsType.AdminLog,
        layout: defaultLayout,
      },
      component: Log,
    },
    {
      path: '/scrolltest',
      meta: { layout: defaultLayout },
      component: ScrollTest,
    },
    {
      path: '/visualguide',
      meta: { layout: simpleLayout },
      component: VisualGuidelines,
    },
    {
      path: '/responsive',
      meta: { layout: defaultLayout },
      component: ResponsiveBoxDemo,
    },
    {
      path: ROUTE_PROFILE,
      meta: { layout: defaultLayout },
      component: ProfilePage,
    },
    {
      path: '/:catchAll(.*)*',
      component: Error404Page,
      meta: { layout: simpleLayout },
    },
  ],
  history: createWebHistory(),
});

export default router;
