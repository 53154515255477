export const RettighedsType = {
  DeveloperMode: 'DeveloperMode',

  AppAdmin: 'AppAdmin',
  AdminBruger: 'AdminBruger',
  AdminRolle: 'AdminRolle',
  AdminLog: 'AdminLog',
  AdminSupportSletRevision: 'AdminSupportSletRevision',
  LetguideRaadgiver: 'LetguideRaadgiver',
  LetguideAdmin: 'LetguideAdmin',
};
